.amendmentEntry,
.salesWarning {
  color: red !important;
}
.salesItemUsed {
  color: rgba(179, 179, 179, 0.56) !important;
}

.transferEntry {
  color: blue !important;
}
.transferEntryAxapta {
  color: dodgerblue !important;
}
